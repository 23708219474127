import { Injectable } from '@angular/core';
import { Feedback, FeedbackCreationData, ResponseRate } from '@reflact/prmfeedback';
import { mapEnsureInitialVal, removeFromArray } from '@reflact/tsutil';
import { Subject } from 'rxjs';
import { LoginService } from '../views/login/login.service';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  public feedbacks: Feedback[] = [];
  public myFeedbacks: Feedback[] = [];
  public feedbackMap: Map<string, Feedback> = new Map<string, Feedback>();
  public feedbackCreated$: Subject<Feedback> = new Subject();
  public feedbackUpdated$: Subject<Feedback> = new Subject();
  public feedbackDeleted$: Subject<Feedback> = new Subject();
  public feedbackStarted$: Subject<Feedback> = new Subject();
  public feedbackEnded$: Subject<Feedback> = new Subject();
  public feedbackIdToReponseRates: Map<string, ResponseRate[]> = new Map();
  public error$: Subject<{ message: string }> = new Subject();

  constructor(public socketService: SocketService, public loginService: LoginService) { }

  public clearAllData(): void {
    this.feedbacks.splice(0);
    this.feedbackMap.clear();
    this.feedbackIdToReponseRates.clear();
  }

  async loadData() {
    this.clearAllData();
    if (this.loginService.loggedInUser.type == 'feedbackgiver') {
      return;
    }
    this.feedbacks = await this.loadFeedacks();
    this.feedbackMap = new Map(this.feedbacks.map(a => [a._id, a]));
    this.myFeedbacks = this.feedbacks.filter(f => f.feedbackreceiver_id == this.loginService.loggedInUser$.value._id);
    await this.loadResponseRates(this.feedbacks);
  }

  public loadFeedacks() {
    return new Promise<Feedback[]>((resolve, reject) => {
      this.socketService.socket.emit('getFeedbacks', {}, (data) => {
        if (data.status == 'ok') {
          resolve(data.feedbacks);
        } else {
          reject(data);
        }
      });
    });
  }

  public loadResponseRates(feedbacks: Feedback[]) {
    this.feedbackIdToReponseRates.clear();
    feedbacks.forEach(f => this.feedbackIdToReponseRates.set(f._id, []));
    return new Promise<ResponseRate[]>((resolve, reject) => {
      this.socketService.socket.emit('getResponseRates', {}, (data) => {
        if (data.status == 'ok') {
          data.responseRates.forEach(rr => {
            mapEnsureInitialVal(this.feedbackIdToReponseRates, rr.feedback_id, []);
            this.feedbackIdToReponseRates.get(rr.feedback_id).push(rr);
          });
          resolve(data.responseRates);
        } else {
          reject(data);
        }
      });
    });
  }

  public sendReminder(feedback: Feedback): Promise<Feedback> {
    return new Promise<Feedback>((resolve, reject) => {
      if (feedback.status != 'running') {
        reject('Feedback is not running');
        return;
      }

      this.socketService.socket.emit('sendReminder', { feedbackId: feedback._id }, (data) => {
        if (data.status === 'ok') {
          resolve(feedback);
        } else {
          reject(data);
        }
      });
    });
  }
  public sendSingleReminder(feedback: Feedback, userId: string): Promise<Feedback> {
    return new Promise<Feedback>((resolve, reject) => {
      if (feedback.status != 'running') {
        reject('Feedback is not running');
        return;
      }

      this.socketService.socket.emit('sendSingleReminder', { feedbackId: feedback._id, userId }, (data) => {
        if (data.status == 'ok') {
          resolve(feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public startFeedback(feedback: Feedback): Promise<Feedback> {
    return new Promise<Feedback>((resolve, reject) => {
      if (feedback.status !== 'nomination') {
        reject('Feedback not in state nomination');
        return;
      }
      this.socketService.socket.emit('startFeedback', { feedback_id: feedback._id, endDate: feedback.endDate }, (data) => {
        if (data.status === 'ok') {
          const fdbToUpdate = this.feedbackMap.get(data.feedback._id);
          fdbToUpdate.endDate = data.feedback.endDate;
          fdbToUpdate.feedbackreceiver_sid = data.feedback.feedbackreceiver_sid;
          fdbToUpdate.feedbackreceiver_tid = data.feedback.feedbackreceiver_tid;
          fdbToUpdate.feedbackreceiver_token = data.feedback.feedbackreceiver_token;
          fdbToUpdate.status = data.feedback.status;
          this.feedbackMap.set(fdbToUpdate._id, fdbToUpdate)
          this.myFeedbacks = this.myFeedbacks.map(f => {
            if (f._id == fdbToUpdate._id) f = fdbToUpdate
            return f;
          })
          this.feedbacks = this.feedbacks.map(f => {
            if (f._id == fdbToUpdate._id) f = fdbToUpdate
            return f;
          })

          this.feedbackStarted$.next(fdbToUpdate);
          resolve(data.feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public endFeedback(feedback: Feedback): Promise<Feedback> {
    return new Promise<Feedback>((resolve, reject) => {
      if (feedback.status !== 'running') {
        reject('Feedback not in state running');
        return;
      }
      this.socketService.socket.emit('endFeedback', { feedback_id: feedback._id }, (data) => {
        if (data.status === 'ok') {
          const fdbToUpdate = this.feedbackMap.get(data.feedback._id);
          fdbToUpdate.feedbackreceiver_sid = data.feedback.feedbackreceiver_sid;
          fdbToUpdate.feedbackreceiver_tid = data.feedback.feedbackreceiver_tid;
          fdbToUpdate.feedbackreceiver_token = data.feedback.feedbackreceiver_token;
          fdbToUpdate.status = data.feedback.status;
          this.feedbackMap.set(fdbToUpdate._id, fdbToUpdate)
          this.myFeedbacks = this.myFeedbacks.map(f => {
            if (f._id == fdbToUpdate._id) f = fdbToUpdate
            return f;
          })
          this.feedbacks = this.feedbacks.map(f => {
            if (f._id == fdbToUpdate._id) f = fdbToUpdate
            return f;
          })
          this.feedbackEnded$.next(fdbToUpdate);
          resolve(data.feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public createFeedback(feedback: FeedbackCreationData): Promise<FeedbackCreationData> {
    return new Promise<Feedback>((resolve, reject) => {
      this.socketService.socket.emit('createFeedback', { feedback }, (data) => {
        if (data.status == 'ok') {

          this.feedbacks.push(data.feedback);
          this.feedbackMap.set(data.feedback._id, data.feedback);
          if (data.feedback.feedbackreceiver_id == this.loginService.loggedInUser$.value._id) { this.myFeedbacks.push(data.feedback) }
          this.feedbackCreated$.next(data.feedback);
          this.feedbackIdToReponseRates.set(data.feedback._id, []);

          resolve(data.feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public feedbackStatusToName(f: Feedback) {
    let name = '';
    if (f.archived) {
      return $localize`:@@globalArchived:Archiviert`;
    }
    switch (f.status) {
      case 'ended':
        name = $localize`:@@globalDone:Abgeschlossen`;
        break;
      case 'nomination':
        name = $localize`:@@navNomination:Nominierung`;
        break;
      case 'running':
        name = $localize`:@@globalFeedbackRunning:Feedback läuft`;
        break;
      default:
        name = $localize`:@@globalUndefined:Unbekannt`;
    }
    return name;
  }

  public deleteFeedback(feedback: Feedback) {
    return new Promise<Feedback>((resolve, reject) => {
      this.socketService.socket.emit('deleteFeedback', { feedback_id: feedback._id }, (data) => {
        if (data.status == 'ok') {
          const deletedFeedback = this.feedbackMap.get(data.deletedId);
          if (deletedFeedback.status !== 'ended') {
            this.feedbackMap.delete(data.deletedId);
            removeFromArray(this.feedbacks, (f => f._id == feedback._id));
            removeFromArray(this.myFeedbacks, (f => f._id == feedback._id));
            this.feedbackDeleted$.next(deletedFeedback);
          } else {
            deletedFeedback.archived = true;
          }
          resolve(feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public updateFeedback(feedback: Feedback) {
    return new Promise<Feedback>((resolve, reject) => {
      this.socketService.socket.emit('editFeedback', { feedback }, (data) => {
        if (data.status == 'ok') {
          this.feedbackUpdated$.next(data.feedback);
          this.feedbackMap.get(data.feedback._id).name = feedback.name;
          resolve(data.feedback);
        } else {
          reject(data);
        }
      });
    });
  }

  public restartFeedbackByAdmin(feedback: Feedback) {
    return new Promise<Feedback>((resolve, reject) => {
      this.socketService.socket.emit('restartFeedbackByAdmin', { feedback_id: feedback._id, endDate: feedback.endDate }, (data) => {
        if (data.status == 'ok') {
          this.feedbackUpdated$.next(data.feedback);
          this.feedbackMap.get(data.feedback._id).status = data.feedback.status;
          this.feedbackMap.get(data.feedback._id).endDate = data.feedback.endDate;
          resolve(data.feedback);
        } else {
          reject(data);
        }
      });
    });
  }
}
