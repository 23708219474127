import { Injectable } from '@angular/core';
import { UserGroup, UserGroupEditData } from '@reflact/prmfeedback';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class UserGroupsService {

  public userGroups: UserGroup[] = []
  public userGroupsMap: Map<string, UserGroup> = new Map()

  constructor(public socketService: SocketService) { }

  public clearAllData() {
    this.userGroups.splice(0);
    this.userGroupsMap.clear()
  }

  public async loadData() {
    this.clearAllData();
    this.userGroups.push(...(await this.getUserGroups()));
    this.userGroupsMap = new Map(this.userGroups.map(a => [a._id, a]));
  }


  async getUserGroups() {
    return new Promise<UserGroup[]>((resolve, reject) => {
      this.socketService.socket.emit("getUserGroups", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.userGroups)
        } else {
          reject(data)
        }
      })
    })
  }

  async addUserGroup(userGroup: UserGroup) {
    return new Promise<UserGroup>((resolve, reject) => {
      this.socketService.socket.emit("createUserGroup", { userGroup }, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.userGroup)
        } else {
          reject(data)
        }
      })
    })
  }

  async editUserGroup(userGroup: UserGroupEditData) {
    return new Promise<UserGroup>((resolve, reject) => {
      this.socketService.socket.emit("editUserGroup", { userGroup }, async (data) => {
        if (data.status == "ok") {
          await this.loadData()
          resolve(data.userGroup)
        } else {
          reject(data)
        }
      })
    })
  }

  async deleteUserGroup(groupId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit("deleteUserGroup", { userGroupId: groupId }, async (data) => {
        if (data.status == "ok") {
          await this.loadData()
          resolve(data.deletedId)
        } else {
          reject(data)
        }
      })
    })
  }

  async addSurveyToUserGroup(userGroupId: string, surveyId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit('addUserGroupToSurvey', { userGroupId, surveyId }, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.survey._id);
        } else {
          reject(data);
        }
      });
    });
  }

  async removeSurveyFromUserGroup(userGroupId: string, surveyId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit('removeUserGroupFromSurvey', { userGroupId, surveyId }, async (data) => {
        if (data.status == "ok") {
          await this.loadData();
          resolve(data.survey._id);
        } else {
          reject(data);
        }
      });
    });
  }

}
