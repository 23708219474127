import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BenchmarkConfig, Customer, CustomerCreationData, LimeSurvey, PrmUser, Survey, SurveyCreationData } from '@reflact/prmfeedback';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { LoginService } from '../views/login/login.service';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public limeSurveys: LimeSurvey[] = [];
  public limeSurveysMap: Map<string, LimeSurvey> = new Map();
  public limeSurveys$: BehaviorSubject<LimeSurvey[]> = new BehaviorSubject([]);
  public currentCustomersLimeSurveys: LimeSurvey[] = []
  public currentCustomersLimeSurveys$: BehaviorSubject<LimeSurvey[]> = new BehaviorSubject([]);
  public surveys: Survey[] = []
  public surveyMap: Map<string, Survey> = new Map()
  public publishedSurveys$: BehaviorSubject<Survey[]> = new BehaviorSubject([]);
  public surveyUpdated$: Subject<Survey> = new Subject()
  public surveyCreated$: Subject<Survey> = new Subject()
  public surveyDeleted$: Subject<Survey> = new Subject()
  public customers: Customer[] = []
  public customerMap: Map<string, Customer> = new Map()
  public customerCreated$: Subject<Customer> = new Subject()
  public customerUpdated$: Subject<Customer> = new Subject()
  public customerDeleted$: Subject<Customer> = new Subject()
  public benchmarkUpdated$: Subject<Customer> = new Subject()
  public error$: Subject<{ message: string }> = new Subject()

  constructor(public socketService: SocketService, public loginService: LoginService, protected http: HttpClient) {
  }

  public clearAllData() {
    this.customers.splice(0);
    this.customerMap.clear()
    this.limeSurveys.splice(0)
    this.limeSurveysMap.clear()
    this.surveys.splice(0)
    this.currentCustomersLimeSurveys.splice(0)
    this.currentCustomersLimeSurveys$.next(null)
  }

  async loadData() {
    this.clearAllData();
    if (this.loginService.loggedInUser.type == "feedbackgiver") {
      return
    }
    this.customers.push(...(await this.loadCustomers()));
    this.customerMap = new Map(this.customers.map(a => [a._id, a]));
    this.limeSurveys.push(...(await this.loadLimeSurveys()));
    this.limeSurveysMap = new Map(this.limeSurveys.map(a => [a.sid, a]));
    this.currentCustomersLimeSurveys.push(...this.customerMap.get(this.loginService.loggedInUser.customer_id).limesurvey_ids.map(lid => this.limeSurveysMap.get(lid)));
    this.currentCustomersLimeSurveys$.next(this.currentCustomersLimeSurveys);
    this.surveys.push(...(await this.loadSurveys()));
    this.surveyMap = new Map(this.surveys.map(a => [a._id, a]));
  }

  public loadLimeSurveys() {
    return new Promise<LimeSurvey[]>((resolve, reject) => {
      if (["prmadmin", "hr", "groupadmin"].indexOf(this.loginService.loggedInUser.type) < 0) {
        return resolve([])

      }
      this.socketService.socket.emit("getLimeSurveys", {}, (data) => {
        if (data.status == "ok") {
          this.limeSurveys$.next(data.limesurveys);
          resolve(data.limesurveys)
        } else {
          reject(data)
        }
      })
    })
  }

  public loadSurveys() {
    return new Promise<Survey[]>((resolve, reject) => {
      this.socketService.socket.emit("getSurveys", {}, (data) => {
        if (data.status == "ok") {
          this.publishedSurveys$.next(data.surveys.filter(s => s.status != 'draft'));
          resolve(data.surveys)
        } else {
          reject(data)
        }
      })
    })
  }

  public loadCustomers() {
    return new Promise<Customer[]>((resolve, reject) => {
      this.socketService.socket.emit("getCustomers", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.customers)
        } else {
          reject(data)
        }
      })
    })
  }

  public createCustomer(customer: CustomerCreationData): Promise<Customer> {
    return new Promise<Customer>((resolve, reject) => {
      this.socketService.socket.emit("createCustomer", { customer }, (data) => {
        if (data.status == "ok") {
          if (data.customer == null) {
            this.error$.next({ message: $localize`:@@feedbackSuiteCannotCreateClient:Mandant kann nicht angelegt werden` });
            return reject($localize`:@@feedbackSuiteCouldNotCreateClient:Mandant konnte nicht angelegt werden`);
          }
          this.customers.push(data.customer)
          this.customerMap.set(data.customer._id, data.customer)
          this.customerCreated$.next(data.customer)
          resolve(data.customer)
        } else {
          reject(data)
        }
      })
    })
  }

  public deleteCustomer(customer: Customer) {
    return new Promise<Customer>((resolve, reject) => {
      this.socketService.socket.emit("deleteCustomer", { customer_id: customer._id }, (data) => {
        if (data.status == "ok") {
          if (data.deletedId == null) {
            this.error$.next({
              message: $localize`:@@feedbackSuiteCannotDeleteClient:Mandant kann nicht gelöscht werden\nFehlermeldung: ` + data.message
            });
            return reject($localize`:@@feedbackSuiteCouldNotDeleteClient:Mandant konnte nicht gelöscht werden`);
          }

          this.removeCustomer(customer)
          this.customerDeleted$.next(customer)
          resolve(customer)
        } else {
          reject(data)
        }
      })
    })
  }

  public async uploadLogo(file: File, customer_id: string) {
    const formData: FormData = new FormData();
    formData.append('file', file)
    const req = this.http.post("/api/customer/uploadlogo/" + customer_id, formData, { headers: this.loginService.getAuthHeaders() })
    const result = await firstValueFrom(req)
    return result
  }

  public async deleteLogo(customer_id: string) {
    const req = this.http.post("/api/customer/deletelogo/" + customer_id, {}, { headers: this.loginService.getAuthHeaders() })
    const result = await firstValueFrom(req)
    return result
  }

  public updateCustomer(customer: Customer) {
    return new Promise<Customer>((resolve, reject) => {
      this.socketService.socket.emit("editCustomer", {
        customer: customer
      }, (data) => {
        if (data.status == "ok") {
          for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i]._id == data.customer._id) {
              this.customers[i] = data.customer
            }
          }
          this.customerMap.set(data.customer._id, data.customer)
          this.customerUpdated$.next(data.customer)
          this.currentCustomersLimeSurveys.splice(0, 10000)
          this.currentCustomersLimeSurveys.push(...this.customerMap.get(this.loginService.loggedInUser.customer_id).limesurvey_ids.map(lid => this.limeSurveysMap.get(lid)));
          this.currentCustomersLimeSurveys$.next(this.currentCustomersLimeSurveys);

          resolve(data.customer)
        } else {
          reject(data)
        }
      })
    })
  }

  public updateBenchmarks(customer: Customer) {
    return new Promise<Customer>((resolve, reject) => {
      this.socketService.socket.emit("editCustomer", {
        customer: customer
      }, (data) => {
        if (data.status == "ok") {
          this.customerMap.get(data.customer._id)
          this.benchmarkUpdated$.next(data.customer)
          resolve(data.customer)
        } else {
          reject(data)
        }
      })
    })
  }

  public createSurvey(survey: SurveyCreationData): Promise<Survey> {
    return new Promise<Survey>((resolve, reject) => {
      this.socketService.socket.emit("createSurvey", { survey }, (data) => {
        if (data.status == "ok") {
          if (data.survey == null) {
            this.error$.next({ message: $localize`:@@feedbackSuiteCannotCreateSurvey:Survey kann nicht angelegt werden` });
            return reject($localize`:@@feedbackSuiteCouldNotCreateSurvey:Survey konnte nicht angelegt werden`);
          }
          this.surveys.push(data.survey)
          this.surveyMap.set(data.survey._id, data.survey)
          this.publishedSurveys$.next(this.surveys.filter(s => s.status == 'published'));
          this.surveyCreated$.next(data.survey)
          //this.limeSurveysMap.get(data.survey.sid).customerconfigs.push(data.survey)
          resolve(data.survey)
        } else {
          reject(data)
        }
      })
    })
  }

  public deleteSurvey(survey: Survey) {
    return new Promise<Survey>((resolve, reject) => {
      this.socketService.socket.emit("deleteSurvey", { survey_id: survey._id }, (data) => {
        if (data.status == "ok") {
          if (data.deletedId == null) {
            this.error$.next({ message: $localize`:@@feedbackSuiteCannotDeleteSurvey:Survey kann nicht gelöscht werden\nFehlermeldung: ` + data.message });
            return reject($localize`:@@feedbackSuiteCouldNotDeleteSurvey:Survey konnte nicht gelöscht werden`);
          }
          this.removeSurvey(survey)
          this.surveyDeleted$.next(survey)
          this.publishedSurveys$.next(this.surveys.filter(s => s.status == "published"));

          resolve(survey)
        } else {
          reject(data)
        }
      })
    })
  }

  public updateSurvey(survey: Survey) {
    return new Promise<Survey>((resolve, reject) => {
      this.socketService.socket.emit("editSurvey", {
        survey
      }, (data) => {
        if (data.status == "ok") {
          const surveyToUpdate = this.surveyMap.get(data.survey._id);
          surveyToUpdate.customer_id = data.survey.customer_id;
          surveyToUpdate.name = data.survey.name;
          surveyToUpdate.perspectives = data.survey.perspectives;
          surveyToUpdate.self_sid = data.survey.self_sid;
          surveyToUpdate.other_sid = data.survey.other_sid;
          surveyToUpdate.status = data.survey.status;
          surveyToUpdate.feedbackDuration = data.survey.feedbackDuration;
          this.surveyUpdated$.next(data.survey);
          this.publishedSurveys$.next(this.surveys.filter(s => s.status == 'published'));

          resolve(data.survey)
        } else {
          reject(data)
        }
      })
    })
  }

  private removeSurvey(survey: Survey) {
    this.surveys.splice(this.surveys.findIndex(u => u._id == survey._id), 1);
    this.surveyMap.delete(survey._id)
  }

  private removeCustomer(customer: Customer) {
    this.customerMap.delete(customer._id)
    this.customers.splice(this.customers.findIndex(u => u._id == customer._id), 1);
  }

  public getBenchmarksForUser(user: PrmUser): BenchmarkConfig[] {
    let configs: BenchmarkConfig[];
    this.customers.forEach(c => {
      if (c._id == user.customer_id) {
        configs = c.benchmarkConfigs;
      }
    })
    return configs;
  }
}
