import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';

export class CachedSubject<T>
  extends BehaviorSubject<T> {

  private _hasValue = false;

  get hasValue(): boolean {
    return this._hasValue;
  }

  private _isLoading = false;

  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(_value: T) {
    super(_value);
  }

  static isEmpty<T>(v: T): boolean {
    return (v === null) || (v === undefined);
  }

  static isNotEmpty<T>(v: T): boolean {
    return !CachedSubject.isEmpty(v);
  }

  /**
   * Use in <code>.pipe(catchError(this._styleInfo.nextError))</code> to pass errors into CachedSubject.
   */
  nextError = (error?: T): Observable<T> => {
    this._isLoading = false;
    super.error(error);
    return throwError(error);
  };

  /**
   * mark loading finished
   * @deprecated please use {@link CachedSubject.nextError} instead
   */
  queryDone = (): void => {
    this._isLoading = false;
  };

  /**
   * check if loading data is required and prevent duplicate queries
   */
  queryStart = (): boolean => {
    if (!(this.hasValue || this.isLoading)) {
      this._isLoading = true;
      return true;
    }
    return false;
  };

  reset = (): void => {
    this.next(null as any);
  };

  /**
   * start with value and ignore any empty values (for reset)
   * Caution! should only be used in getters or methods, not in constructors to allow fetching live data
   */
  withInitial = (): Observable<T> => {
    return this.asObservable()
      .pipe(startWith<T>(this.value));
  };

  /**
   * ignore any empty values (for reset)
   */
  withoutEmptyValues = (): Observable<T> => {
    return this.asObservable()
      .pipe(filter(CachedSubject.isNotEmpty));
  };

  /**
   * start with value and ignore any empty values (for reset)
   * Caution! should only be used in getters or methods, not in constructors to allow fetching live data
   */
  withoutEmptyValuesWithInitial = (): Observable<T> => {
    return this.withInitial()
      .pipe(filter(CachedSubject.isNotEmpty));
  };

}
