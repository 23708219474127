import { Injectable } from '@angular/core';
import { FeedbackClientToServerEvents, FeedbackServerToClientEvents } from '@reflact/prmfeedback';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Socket, io } from "socket.io-client";
export type PrmSocket = Socket<FeedbackServerToClientEvents, FeedbackClientToServerEvents>

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public error$: Subject<any> = new Subject();
  private static socket_: PrmSocket
  private wasDisconnected = false;

  constructor(public toastr: ToastrService) { }

  public get socket(): PrmSocket {
    if (!SocketService.socket_) {
      SocketService.socket_ = io('//');
      SocketService.socket_.on("error", (data) => {
        this.error$.next(data);
      })
      SocketService.socket_.on("connect", () => {
        if (this.wasDisconnected) {
          this.wasDisconnected = false;
          this.toastr.success($localize`:@@connected:Erfolgreich mit Server verbunden`);
        }
      })
      SocketService.socket_.on("disconnect", () => {
        this.wasDisconnected = true;
        this.toastr.error($localize`:@@disconnected:Verbindung zum Server wurde getrennt`);
      })
    }
    return SocketService.socket_;
  }
}