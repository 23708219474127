import { Injectable } from '@angular/core';
import { Coach } from '@reflact/prmfeedback';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class CoachesService {
  public coaches: Coach[] = []
  public coachesMap: Map<string, Coach> = new Map()

  constructor(public socketService: SocketService) { }

  public clearAllData() {
    this.coaches.splice(0);
    this.coachesMap.clear()
  }

  public async loadData() {
    this.clearAllData();
    this.coaches.push(...(await this.getCoaches()));
    this.coachesMap = new Map(this.coaches.map(a => [a._id, a]));
  }

  public async getCoaches() {
    return new Promise<Coach[]>((resolve, reject) => {
      this.socketService.socket.emit("getCoaches", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.coaches);
        } else {
          reject(data);
        }
      });
    });
  }

  public async addCoach(coach: Coach) {
    return new Promise<Coach>((resolve, reject) => {
      this.socketService.socket.emit("createCoach", { coach }, (data) => {
        if (data.status == "ok") {
          resolve(data.coach);
        } else {
          reject(data);
        }
      });
    });
  }

  public async editCoach(coach: Coach) {
    return new Promise<Coach>((resolve, reject) => {
      this.socketService.socket.emit("editCoach", { coach }, (data) => {
        if (data.status == "ok") {
          resolve(data.coach);
        } else {
          reject(data);
        }
      });
    });
  }

  public async deleteCoach(coachId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit("deleteCoach", { coachId }, (data) => {
        if (data.status == "ok") {
          resolve(data.deletedId);
        } else {
          reject(data);
        }
      });
    });
  }
}