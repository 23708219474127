import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AllPrmUserType,
  CheckLoginMechanismResult,
  Customer,
  Login1FactorResult,
  Login2FactorResult,
  LoginHistoryEntry,
  staticTranslate
} from '@reflact/prmfeedback';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { SocketService } from '../../shared/SocketService';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private static loggedIn: boolean = false;
  private email: string;
  public loginStatus = new Subject<Login1FactorResult>();
  public confirmStatus = new Subject<Login2FactorResult>();
  public loggedInUser$ = new BehaviorSubject<AllPrmUserType>(null);
  public adminUserSwitched$ = new Subject<Customer>();
  public loggedInUser: AllPrmUserType = null;
  public loginHistory: LoginHistoryEntry[] = [];
  public openWindow: Window = undefined;
  public shareToken: string = undefined;

  constructor(private socketService: SocketService,
    private router: Router,
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute
  ) {
    socketService.socket.on('disconnect', this.socketDisconnected.bind(this));
    socketService.socket.on("ssoLoginComplete", this.loginWithSSO.bind(this));
    this.loggedInUser$.subscribe(u => {
      this.loggedInUser = u;
      this.checkForRedirectLanguage();
    });
  }

  private nextLoginUser(u: AllPrmUserType) {
    if (this.loggedInUser$.value == null) {
      if (u == null) {
        return null;
      } else {
        return this.loggedInUser$.next(u);
      }
    }

    if (this.loggedInUser$.value != null) {
      if (u == null || "" + this.loggedInUser$.value._id != "" + u._id) {
        return this.loggedInUser$.next(u);
      }
    }
  }

  public translate(str: string) {
    if (this.loggedInUser?.language === 'de') {
      return str;
    }
    return staticTranslate(str);
  }

  public getAuthHeaders(): any {
    return {
      token: localStorage.getItem('authToken')
    };
  }

  public getBearerHeader(): string | undefined {
    const token = localStorage.getItem('authToken');
    if (token == undefined) {
      return undefined;
    }
    return 'Bearer ' + token;
  }

  public getShareToken(): string | undefined {
    return this.shareToken;
  }

  public getLoginHistory(): Promise<LoginHistoryEntry[]> {
    return new Promise<LoginHistoryEntry[]>((resolve, reject) => {
      this.socketService.socket.emit('showLoginHistory', {}, (showLoginHistoryResult) => {
        if (showLoginHistoryResult.status === 'ok') {
          resolve(showLoginHistoryResult.entries);
        } else {
          reject(showLoginHistoryResult.entries);
        }
      });
    });
  }

  public socketDisconnected() {
    this.socketService.socket.on('connect', this.reconnectAfterDisconnect.bind(this));
  }

  public reconnectAfterDisconnect() {
    this.socketService.socket.emit('loginbytoken', { token: localStorage.getItem('authToken') }, (loginResult) => {
      if (loginResult.status !== 'ok') {
        window.location.reload(); // hier ist großer mist passiert
      }
    });
    this.socketService.socket.off('connect', this.reconnectAfterDisconnect.bind(this));
  }

  public async checkLoginMechamism(email: string) {
    return new Promise<CheckLoginMechanismResult>((resolve, reject) => {
      this.socketService.socket.emit('checkLoginMechanism', { email }, (result) => {
        resolve(result);
      });
    });
  }

  public login(email: string, password: string) {
    this.socketService.socket.emit('login1Factor', { email, password }, (statusMessage) => {
      this.email = email;
      this.loginStatus.next(statusMessage);
    });
  }

  public confirmLogin(code: string) {
    this.socketService.socket.emit('login2Factor', { email: this.email, code }, (authResult) => {
      if (authResult.status === 'ok') {
        localStorage.setItem('user', JSON.stringify(authResult.user));
        localStorage.setItem('authToken', authResult.token);
        localStorage.removeItem('adminToken');
        LoginService.loggedIn = true;
        this.nextLoginUser(authResult.user);
      }
      this.confirmStatus.next(authResult);
    });
  }

  public loginWithSSO(data) {
    if (data.status === 'ok') {
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('authToken', data.token);
      localStorage.removeItem('adminToken');
      this.socketService.socket.emit('loginbytoken', { token: localStorage.getItem('authToken') }, (loginResult) => {
        if (loginResult.status === 'ok') {
          LoginService.loggedIn = true;
          this.nextLoginUser(data.user);
        }
      })
      if (this.openWindow != null) {
        try {
          this.openWindow.close();
          this.openWindow = undefined;
        } catch (e) {
          console.error("Error closing window")
        }
      }
      this.router.navigate([''])
    }
  }

  public async switchToUser(email?: string) {
    if (this.loggedInUser === null) {
      return;
    }
    const authToken = localStorage.getItem('authToken');
    const url = '/api/CreateTokenByUser/' + email + '/' + authToken;

    if (this.checkAdminToken()) {
      const result = await firstValueFrom(this.httpClient.get<{ token: string }>(url));
      localStorage.setItem('adminToken', authToken);
      localStorage.setItem('authToken', result.token);
    } else {
      localStorage.setItem('authToken', localStorage.getItem('adminToken'));
      localStorage.removeItem('adminToken');
    }

    window.location.href = '/';
  }

  public checkAdminToken() {
    return localStorage.getItem('adminToken') === null;
  }

  public checkForRedirectLanguage() {
    if (this.loggedInUser == null || window.location.hostname === 'localhost') { return; }
    const pathThatShouldBe = `/app/${this.loggedInUser.language}/`;
    if (window.location.pathname !== pathThatShouldBe) {
      window.location.pathname = pathThatShouldBe;
    }
  }

  public loginByToken(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = localStorage.getItem('authToken');
      if (token !== null) {
        this.socketService.socket.emit('loginbytoken', { token }, (loginResult) => {
          if (loginResult.status === 'forbidden') {
            localStorage.removeItem('user');
            localStorage.removeItem('authToken');
            LoginService.loggedIn = false;
            this.nextLoginUser(null);
            resolve(false);
          }

          if (loginResult.status === 'ok') {
            localStorage.setItem('user', JSON.stringify(loginResult.user));
            localStorage.setItem('authToken', loginResult.token);
            LoginService.loggedIn = true;
            this.nextLoginUser(loginResult.user);
            if (this.router.url === '/login') {
              this.router.navigate(['']);
            }
            resolve(true);
          } else {
            resolve(false);
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  public logout() {
    this.socketService.socket.emit('logout', {}, (logoutResult) => {
      if (logoutResult.status === 'ok') {
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        localStorage.removeItem('adminToken');
        LoginService.loggedIn = false;
        this.nextLoginUser(null);
        this.router.navigate(['login']);
      }
    });
  }

  public switchAdminCustomer(customer_id: string) {
    this.socketService.socket.emit('switchAdminCustomer', { customer_id }, (data) => {
      this.loggedInUser.customer_id = data.customer._id;
      this.adminUserSwitched$.next(data.customer);
    });
  }
  public isLoggedIn(): boolean {
    return LoginService.loggedIn;
  }

  public requestPassword(email: string) {
    this.socketService.socket.emit('requestpw', { email }, (result) => { });
  }

  public setMail(email: string) {
    this.email = email;
  }

  public navigateAfterLogin() {
    this.router.navigate([this.getComponentAfterLogin(this.loggedInUser$.value.type)]);
  }

  private getComponentAfterLogin(type: string) {
    let component = '';
    switch (type) {
      case 'prmadmin':
        component = 'surveys';
        break;
      case 'hr':
        component = 'surveys';
        break;
      case 'groupadmin':
        component = 'surveys';
        break;
      case 'feedbackreceiver':
        component = 'my-feedbacks';
        break;
      case 'feedbackgiver':
        component = 'feedback-invitations';
        break;
      default:
        component = 'help-area';
        break;
    }
    return component;
  }
}