import { Injectable } from '@angular/core';
import {
  CheckNominationImportResult,
  DeleteNominationResult,
  Feedback,
  Nomination,
  NominationCreationData,
  NominationSearchUser,
  SurveyInvitation
} from '@reflact/prmfeedback';
import { mapEnsureInitialVal, removeFromArray } from '@reflact/tsutil';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CachedSubject } from '../core/cached-subject';
import { LoginService } from '../views/login/login.service';
import { SocketService } from './SocketService';
import { FeedbackService } from './feedback.service';
import { UserService } from './user.service';


@Injectable({
  providedIn: 'root'
})
export class NominationService {
  public allNominations: Nomination[] = [];
  public error$: Subject<{ message: string }> = new Subject();
  public feedbackIdToNominations: Map<string, Nomination[]> = new Map();
  public myInvitations: SurveyInvitation[] = [];
  readonly myInvitations$: Observable<SurveyInvitation[]>;
  public nominamtionCreated$: Subject<Nomination> = new Subject();
  public nominationDeleted$: Subject<Nomination> = new Subject();
  public nominationMap: Map<string, Nomination> = new Map<string, Nomination>();
  public nominationUpdated$: Subject<Nomination> = new Subject();
  public userSearchResult$: BehaviorSubject<NominationSearchUser[]> = new BehaviorSubject<NominationSearchUser[]>([]);
  private _myInvitations$ = new CachedSubject<SurveyInvitation[]>([]);

  constructor(
    public socketService: SocketService,
    public loginService: LoginService,
    public feedBackservice: FeedbackService,
    private userService: UserService
  ) {
    // initialize myInvitations$
    this.myInvitations$ = this._myInvitations$.asObservable();

    this.feedBackservice.feedbackStarted$.subscribe(o => {
      this.refreshNominationsAfterFeedbackStart(o).then();
    });
    this.feedBackservice.feedbackCreated$.subscribe(o => { // neues feedback bekommt leere nomination
      this.feedbackIdToNominations.set(o._id, []);
    });
    this.feedBackservice.feedbackDeleted$.subscribe(o => { // neues feedback bekommt leere nomination
      const nominations = this.feedbackIdToNominations.get(o._id);
      nominations.forEach(n => {
        this.nominationMap.delete(n._id);
        removeFromArray(this.allNominations, (ne => ne._id === n._id));
      });
      this.feedbackIdToNominations.delete(o._id);
    });
  }

  public clearAllData(): void {
    this.allNominations.splice(0);
    this.myInvitations.splice(0);
    this._myInvitations$.next([]);
    this.nominationMap.clear();
    this.feedbackIdToNominations.clear();
  }

  public async createNomination(nomination: NominationCreationData): Promise<Nomination> {
    return new Promise<Nomination>((resolve, reject) => {
      this.socketService.socket.emit('createNomination', { nomination }, (data) => {
        if (data.status === 'ok') {
          this.userService.addUpdateUser(data.user);
          this.allNominations.push(data.nomination);
          this.nominationMap.set(data.nomination._id, data.nomination);
          if (data.nomination.feedbackgiver_id === this.loginService.loggedInUser$.value._id) {
            this.reloadInvitations().then();
          }
          mapEnsureInitialVal(this.feedbackIdToNominations, data.nomination.feedback_id, []);
          this.feedbackIdToNominations.get(data.nomination.feedback_id).push(data.nomination);
          this.nominamtionCreated$.next(data.nomination);
          resolve(data.nomination);
        } else if (data.status === 'invalidrequest' && data.message === 'duplicate user') {
          resolve(data.nomination);
        } else {

          reject(data);
        }
      });
    });
  }

  public deleteNomination(nomination_id: string, feedback_id: string): Promise<Nomination> {
    return new Promise<Nomination>((resolve, reject) => {
      this.socketService.socket.emit('deleteNomination', { nomination_id }, (data: DeleteNominationResult) => {
        if (data.status === 'ok') {
          const index = this.allNominations.findIndex(n => n._id === data.deleted_id);
          const nomination = this.allNominations.splice(index, 1)[0];
          this.nominationMap.delete(data.deleted_id);
          const mapIndex = this.feedbackIdToNominations.get(feedback_id).findIndex(n => n._id === data.deleted_id);
          if (mapIndex > -1) {
            this.feedbackIdToNominations.get(feedback_id).splice(mapIndex, 1);
          }
          this.nominationDeleted$.next(nomination);
          resolve(data.deleted_id);
        } else {
          reject(data);
        }
      });
    });
  }

  async loadData() {
    this.clearAllData();
    await this.reloadInvitations();

    if (this.loginService.loggedInUser.type === 'feedbackgiver') {
      return;
    }
    this.allNominations = await this.loadNominations();
    this.nominationMap = new Map(this.allNominations.map(a => [a._id, a]));
    this.feedBackservice.feedbacks.forEach(f => this.feedbackIdToNominations.set(f._id, []));

    this.allNominations.forEach((n) => {
      mapEnsureInitialVal(this.feedbackIdToNominations, n.feedback_id, []);
      this.feedbackIdToNominations.get(n.feedback_id).push(n);
    });
  }

  public loadNominations() {
    return new Promise<Nomination[]>((resolve, reject) => {
      this.socketService.socket.emit('getNominations', {}, (data) => {
        if (data.status === 'ok') {
          resolve(data.nominations);
        } else {
          reject(data);
        }
      });
    });
  }

  public loadSurveyInvitions() {
    return new Promise<SurveyInvitation[]>((resolve, reject) => {
      this.socketService.socket.emit('getSurveyInvitations', {}, (data) => {
        if (data.status === 'ok') {
          resolve(data.surveysToDo);
        } else {
          reject(data);
        }
      });
    });
  }

  public refreshNominationsAfterFeedbackStart(feedback: Feedback) {
    return new Promise<Nomination[]>((resolve, reject) => {
      this.socketService.socket.emit('getNominations', { feedback_id: feedback._id }, (data) => {
        if (data.status === 'ok') {
          data.nominations.forEach(n => {
            const nToUpdate = this.nominationMap.get(n._id);
            nToUpdate.sid = n.sid;
            nToUpdate.token = n.token;
            nToUpdate.tid = n.tid;
          });
          resolve(data.nominations);
        } else {
          reject(data);
        }
      });
    });
  }

  public async reloadInvitations() {
    this.myInvitations.splice(0);
    this.myInvitations.push(...(await this.loadSurveyInvitions()));
    this.socketService.socket.emit('getSurveyInvitations', {}, (data) => {
      this.myInvitations = data.surveysToDo;
      this._myInvitations$.next(data.surveysToDo);
    });
  }

  public searchUserForNomination(search: string, feedbackId: string) {
    this.socketService.socket.emit('nominationSearch', { search: search, feedback_id: feedbackId }, (data) => {
      data.users = data.users.filter(u => u._id !== this.loginService.loggedInUser._id);
      this.userSearchResult$.next(data.users);
    });
  }

  public checkNominationImport(surveyId: string, feedbackId: string, nominations: []): Promise<CheckNominationImportResult> {
    return new Promise<CheckNominationImportResult>((resolve, reject) => {
      this.socketService.socket.emit('checkNominationImport', { survey_id: surveyId, feedback_id: feedbackId, nominations }, (data: CheckNominationImportResult) => {
        if (data.status === 'ok') {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }
}
