import { Injectable } from '@angular/core';
import { AllPrmUserType, DeleteUsersResult, InviteUserResult, PrmUserType, PrmUserTypeArr, UpdateUserResult, UserForCreationData, UserToArchiveResult } from '@reflact/prmfeedback';
import { removeFromArray } from '@reflact/tsutil';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoginService } from '../views/login/login.service';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users: AllPrmUserType[] = []
  public userMap: Map<string, AllPrmUserType> = new Map();
  public usersByType: Map<PrmUserType, AllPrmUserType[]> = new Map();
  public usersByTypeMap: Map<PrmUserType, Map<string, AllPrmUserType>> = new Map();
  public usersCreated$: Subject<AllPrmUserType[]> = new Subject()
  public userUpdated$: Subject<AllPrmUserType> = new Subject()
  public usersDeleted$: Subject<AllPrmUserType[]> = new Subject()
  public error$: Subject<{ message: string }> = new Subject()
  public isDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false)
  public languages = ['en', 'de'];

  constructor(public socketService: SocketService, public loginService: LoginService) {
    PrmUserTypeArr.forEach(type => {
      this.usersByType.set(type, [])
      this.usersByTypeMap.set(type, new Map())
    })
  }

  async clearAllData() {
    this.users.splice(0);
    this.userMap.clear()
    PrmUserTypeArr.forEach(type => {
      this.usersByTypeMap.get(type).clear()
      this.usersByType.get(type).splice(0)
    })
  }

  async loadData() {

    this.clearAllData();
    this.users.splice(0);
    this.userMap.clear()
    this.users = await this.loadUsers()
    this.userMap = new Map(this.users.map(a => [a._id, a]));
    PrmUserTypeArr.forEach(type => {
      this.usersByType.get(type).push(...this.users.filter(u => u.type == type))
      this.usersByType.get(type).forEach(u => {
        this.usersByTypeMap.get(type).set(u._id, u)
      })
    })
    this.isDataLoaded.next(true)
  }

  public loadUsers() {
    return new Promise<AllPrmUserType[]>((resolve, reject) => {
      this.socketService.socket.emit("getUsers", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.users)
        } else {
          reject(data)
        }
      })
    })
  }

  public createUsers(users: UserForCreationData[]): Promise<AllPrmUserType[]> {
    return new Promise<AllPrmUserType[]>((resolve, reject) => {
      this.socketService.socket.emit("createUsers", { users }, (data) => {
        if (data.status == "ok") {
          if (data.users.length == 0) {
            this.error$.next({ message: $localize`:@@feedbackSuiteCannotCreateUser:Es konnten keine Nutzer angelegt werden` });
            return reject($localize`:@@feedbackSuiteCouldNotCreateUser:Nutzer konnten nicht angelegt werden`);
          }

          data.users.forEach(user => {
            this.fixBenchmarkValues(user);
            // Nutzer könnten hierdurch geupdatet wurden sein und tauchen dann doppelt in der liste auf
            if (!this.userMap.has(user._id) || this.users.findIndex(u => u._id == user._id) < 0) {
              this.users.push(user)
              this.usersByType.get(user.type).push(user)
              this.usersByTypeMap.get(user.type).set(user._id, user);
            } else {
              const userindex = this.users.findIndex(u => u._id == user._id)
              const oldUser = this.users[userindex]
              removeFromArray(this.usersByType.get(oldUser.type), (u => u._id == oldUser._id))
              this.usersByTypeMap.get(oldUser.type).delete(oldUser._id);
              this.users[userindex] = user
              this.usersByType.get(user.type).push(user)
              this.usersByTypeMap.get(user.type).set(user._id, user);
            }
            this.userMap.set(user._id, user)
          });
          this.usersCreated$.next(data.users)
          resolve(data.users)
        } else {
          reject(data)
        }
      })
    })
  }

  public deleteUsers(users: AllPrmUserType[]) {
    return new Promise<DeleteUsersResult>((resolve, reject) => {
      this.socketService.socket.emit("deleteUsers", { userids: users.map(u => u._id) }, (data) => {
        if (data.status == "ok") {
          if (data.deletedCount != users.length) {
            console.warn("User deletetion count does not match count of users to delete", { users, deletedCount: data.deletedCount })
          }
          users.forEach(user => {
            this.removeUser(user)
          })
          this.isDataLoaded.next(true)
          this.usersDeleted$.next(users)
          resolve(data)
        } else {
          reject(data)
        }
      })
    })
  }

  public updateUser(user: AllPrmUserType) {
    return new Promise<UpdateUserResult>((resolve, reject) => {
      this.socketService.socket.emit("updateUser", {
        user: user
      }, (data) => {
        if (data.status == "ok") {
          this.fixBenchmarkValues(data.user);
          this.userMap.get(data.user._id)
          this.isDataLoaded.next(true)
          this.userUpdated$.next(data.user)
          resolve(data)
        } else {
          reject(data)
        }
      })
    })
  }

  public archiveUser(user: AllPrmUserType) {
    return new Promise<UserToArchiveResult>((resolve, reject) => {
      this.socketService.socket.emit('archiveUser', { userId: user._id }, data => {
        if (data.status == "ok") {
          this.removeUser(user);
          this.isDataLoaded.next(true);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }
  public inviteUser(user: AllPrmUserType) {
    return new Promise<InviteUserResult>((resolve, reject) => {
      this.socketService.socket.emit('inviteUser', { userId: user._id }, data => {
        if (data.status == "ok") {

          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }

  public fixBenchmarkValues(user: AllPrmUserType) {
    if (typeof user.benchmarkValues === 'undefined') {
      user.benchmarkValues = [];
    }

    if (!Array.isArray(user.benchmarkValues) && typeof (user.benchmarkValues) === 'object') {
      user.benchmarkValues = Object.values(user.benchmarkValues);
    }
  }

  public userTypeToName(type: PrmUserType) {
    switch (type) {
      case 'hr': return $localize`:@@globalHR:HR-Administrator*in`;
      case 'prmadmin': return $localize`:@@globalPRM:PRM-Administrator*in`;
      case 'feedbackgiver': return $localize`:@@globalFG:Feedbackgeber*in`;
      case 'feedbackreceiver': return $localize`:@@globalFN:Feedbacknehmer*in`;
      case "groupadmin": return $localize`:@@globalGroupAdmin:Team-Administrator*in`;
    }
  }

  public addUpdateUser(user: AllPrmUserType) {
    this.usersByTypeMap.get(user.type).set(user._id, user);
    const mapIndex = this.usersByType.get(user.type).findIndex(u => u._id == user._id);
    if (mapIndex > -1) {
      this.usersByType.get(user.type)[mapIndex] = user
    } else {
      this.usersByType.get(user.type).push(user);
    }
    this.userMap.set(user._id, user);
    const index = this.users.findIndex(u => u._id == user._id);
    if (index > -1) {
      this.users[index] = user
    } else {
      this.users.push(user)
    }
  }

  private removeUser(user: AllPrmUserType) {
    this.usersByTypeMap.get(user.type).delete(user._id)
    this.usersByType.get(user.type).splice(this.usersByType.get(user.type).findIndex(u => u._id == user._id), 1);
    this.userMap.delete(user._id)
    this.users.splice(this.users.findIndex(u => u._id == user._id), 1);
  }
}
