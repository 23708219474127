import { Injectable } from '@angular/core';
import { Subsidiary } from '@reflact/prmfeedback';
import { SocketService } from './SocketService';

@Injectable({
  providedIn: 'root'
})
export class SubsidiaryService {
  public subsidiaries: Subsidiary[] = []
  public subsidiariesMap: Map<string, Subsidiary> = new Map()

  constructor(public socketService: SocketService) { }

  public clearAllData() {
    this.subsidiaries.splice(0);
    this.subsidiariesMap.clear()
  }

  public async loadData() {
    this.subsidiaries.push(...(await this.getSubsidiaries()));
    this.subsidiariesMap = new Map(this.subsidiaries.map(a => [a._id, a]));
  }

  public async getSubsidiaries() {
    return new Promise<Subsidiary[]>((resolve, reject) => {
      this.socketService.socket.emit("getSubsidiaries", {}, (data) => {
        if (data.status == "ok") {
          resolve(data.subsidiaries);
        } else {
          reject(data);
        }
      });
    });
  }

  public async addSubsidiary(subsidiary: Subsidiary) {
    return new Promise<Subsidiary>((resolve, reject) => {
      this.socketService.socket.emit("createSubsidiary", { subsidiary }, (data) => {
        if (data.status == "ok") {
          resolve(data.subsidiary);
        } else {
          reject(data);
        }
      });
    });
  }

  public async editSubsidiary(subsidiary: Subsidiary) {
    return new Promise<Subsidiary>((resolve, reject) => {
      this.socketService.socket.emit("editSubsidiary", { subsidiary }, (data) => {
        if (data.status == "ok") {
          resolve(data.subsidiary);
        } else {
          reject(data);
        }
      });
    });
  }

  public async deleteSubsidiary(subsidiaryId: string) {
    return new Promise<string>((resolve, reject) => {
      this.socketService.socket.emit("deleteSubsidiary", { subsidiaryId }, (data) => {
        if (data.status == "ok") {
          resolve(data.deletedId);
        } else {
          reject(data);
        }
      });
    });
  }
}