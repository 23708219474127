import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Feedback, Nomination, Perspective, PrmUser, ResponseRate, Survey, SurveyInvitation } from '@reflact/prmfeedback';
import { codes } from 'iso-language-codes';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from '../views/login/login.service';
import { SocketService } from './SocketService';
import { CoachesService } from './coaches.service';
import { CustomerService } from './customer.service';
import { FeedbackService } from './feedback.service';
import { FeedbackresultService } from './feedbackresult.service';
import { NominationService } from './nomination.service';
import { SubsidiaryService } from './subsidiary.service';
import { UserGroupsService } from './user-groups.service';
import { UserService } from './user.service';

export type FeedbackInfo = {
  feedback: Feedback,
  survey: Survey
  nominations: Nomination[]
  responseRates: ResponseRate[]
}

export type Languages = {
  value: "en" | "de" | string;
  label: string;
  img: string;
}

@Injectable({
  providedIn: 'root'
})
export class MainService {
  public static singleton: MainService // For Use in MainResolver without new Instance
  public isDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public languages: Languages[] = [
    { value: 'de', label: $localize`:@@globalDE:Deutsch`, img: 'de' },
    { value: 'en', label: $localize`:@@globalEN:Englisch`, img: 'gb' }
  ];
  public allLanguages: Languages[] = [];

  constructor(public feedbackService: FeedbackService,
    public customerService: CustomerService,
    public userService: UserService,
    public socketService: SocketService,
    public nominationService: NominationService,
    public feedbackResultService: FeedbackresultService,
    public userGroupsService: UserGroupsService,
    public loginService: LoginService,
    public subsidiaryService: SubsidiaryService,
    public coachService: CoachesService,
    private router: Router
  ) {
    MainService.singleton = this

    this.loginService.loggedInUser$.subscribe(o => {
      this.loadData(o);
    })

    this.loginService.adminUserSwitched$.subscribe(async o => {
      await this.router.navigate(["/"]);
      window.location.reload();
    })
  }

  public getLanguageImg(languageValue: Languages['value']) {
    const language = this.languages.find(l => { return l.value == languageValue });
    return language.img;
  }

  public refreshAll() {
    window.location.reload()
  }

  public filterAdminGroupIdsForGroupAdmin(admin: PrmUser, ids: string[]) {
    if (admin.type == 'groupadmin') {
      ids = ids.filter(id => admin.adminGroupsAdmin.includes(id));
    }
    return ids;
  }

  public async loadData(user: PrmUser) {
    if (user == null) {
      this.customerService.clearAllData();
      this.feedbackService.clearAllData();
      this.nominationService.clearAllData();
      this.userService.clearAllData();
      this.userGroupsService.clearAllData();
      return;
    }

    console.group("MainService Loads Data")
    await this.customerService.loadData();
    console.debug("user.type", user.type)
    if (["feedbackreceiver", "prmadmin", "hr", "groupadmin"].indexOf(user.type) >= 0) {
      console.debug("Load User Data");
      await this.userService.loadData();
    }
    console.debug("Load Feedback Data");
    await this.feedbackService.loadData();
    console.debug("Load Nomination Data");
    await this.nominationService.loadData();
    console.debug("Load Usergroups Data");
    await this.userGroupsService.loadData();
    console.debug("MainService Data Loaded", this);
    this.setAllLanguages(user);
    console.groupEnd();
    this.isDataLoaded.next(true);
  }

  private setAllLanguages(user: PrmUser) {
    const lang = new Intl.DisplayNames([user.language], { type: 'language' });
    codes.forEach(code => {
      let translation = lang.of(code.iso639_1);
      if (translation !== code.iso639_1) {
        this.allLanguages.push({
          value: code.iso639_1,
          label: translation + ' (' + code.nativeName + ')',
          img: code.iso639_1
        });
      }
    });
  }

  public getFeedbackInfo(feedback_id: string): FeedbackInfo {
    const responseRates = this.feedbackService.feedbackIdToReponseRates.get(feedback_id);
    const feedback = this.feedbackService.feedbackMap.get(feedback_id)
    const survey = this.customerService.surveyMap.get(feedback.survey_id);
    const nominations = this.nominationService.feedbackIdToNominations.get(feedback_id)
    return { responseRates, feedback, survey, nominations }
  }

  public getSurveyLinkSE(feedback_id: string) {
    const fi = this.getFeedbackInfo(feedback_id);
    return this.getSurveyLinkByToken(fi.feedback.feedbackreceiver_token, fi.feedback.feedbackreceiver_sid)
  }

  public getSurveyLinkInvitation(si: SurveyInvitation) {
    return this.getSurveyLinkByToken(si.token, si.sid)
  }

  public getSurveyLinkByToken(token: string, sid: string) {
    return this.getLimeSurveyDomain() + '/index.php/' + sid + '?token=' + token + '&newtest=Y&lang=' + this.loginService.loggedInUser$.value.language
  }

  public getLimeSurveyDomain(): string {
    const link = window.location.origin
    if (link.indexOf("dev") >= 0 || link.indexOf("localhost") >= 0) {
      return "https://devsurvey.feedback-suite.de"
    } else if (window.location.hostname.startsWith('reflact')) {
      return "https://ask.feedback-suite.de" // reflact live server hat eigenes limesurvey
    } else {
      return "https://survey.feedback-suite.de"
    }
  }

  public minNominationsReached(feedback_id: string) {
    const fi = this.getFeedbackInfo(feedback_id);
    for (const p of fi.survey.perspectives) {
      if (p.min > this.getNominations(feedback_id, p.id).length) {
        return false
      }
    }
    return true
  }

  public maxNominationsReached(feedback_id: string) {
    const fi = this.getFeedbackInfo(feedback_id);
    for (const p of fi.survey.perspectives) {
      if (p.max > this.getNominations(feedback_id, p.id).length) {
        return false
      }
    }
    return true
  }

  public archivedUsersInNominationCount(feedback_id: string) {
    const fi = this.getFeedbackInfo(feedback_id);
    let count = 0;
    for (const p of fi.survey.perspectives) {
      count += this.getNominations(feedback_id, p.id).filter(u => u.archived).length
    }
    return count
  }

  public getResponseCount(feedbackId: string, perspectiveId: string) {
    try {
      const rates = this.getFeedbackInfo(feedbackId).responseRates.find(rr => rr.perspective_id == perspectiveId);
      return rates == null ? 0 : rates.responseCount;
    } catch (e) { // wenn es die rates noch nicht gibt weil feedback neu angelegt
    }
    return 0
  }

  public getNominations(feedbackId: string, perspectiveId: string) {
    return this.getFeedbackInfo(feedbackId).nominations.filter(rr => rr.perspective_id == perspectiveId).map(n => this.userService.userMap.get(n.feedbackgiver_id));
  }

  public getSEInvitationInfo(feedback_id: string) {
    const feedback = this.feedbackService.feedbackMap.get(feedback_id);
    const feedbackReceiver = this.userService.userMap.get(feedback.feedbackreceiver_id)
    const surveyConf = this.customerService.surveyMap.get(feedback.survey_id);
    const seletedPerspective: Perspective = { id: "-1", name: "Selbsteinschtzung", min: 1, max: 1, anonymity: 0, type: 'bound' }
    return { feedback, feedbackReceiver, surveyConf, seletedPerspective }
  }

  public getFEInvitationInfo(nomination_id: string) {
    const nomination = this.nominationService.nominationMap.get(nomination_id);
    const feedback = this.feedbackService.feedbackMap.get(nomination.feedback_id);
    const feedbackReceiver = this.userService.userMap.get(feedback.feedbackreceiver_id)
    const surveyConf = this.customerService.surveyMap.get(feedback.survey_id);
    const seletedPerspective = surveyConf.perspectives.find(p => nomination.perspective_id == p.id)

    return { nomination, feedback, seletedPerspective, feedbackReceiver, surveyConf }
  }

  public async sendSupportMessage(topic: string, text: string) {
    return new Promise<any>((resolve, reject) => {
      this.socketService.socket.emit('support', { topic, text }, (result) => {
        if (result.status == "ok") {
          resolve(result);
        } else {
          reject(result);
        }
      });
    });
  }
}